@use "../tokens";

html {
  background-color: tokens.$color-background-page-lightest;
  color: tokens.$color-text-body-default;
  font-family: tokens.$font-family-body;
  font-weight: 400;
  font-style: normal;
  font-size: 100%;
  line-height: tokens.$font-line-height-body-m;
  // **********************************************************
  // Disables the browser's inflation algorithm.
  // Prevent font scaling in landscape while allowing user zoom
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
    background-color: tokens.$color-background-page-lighter;
  }
}
