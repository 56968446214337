@use "../../styles/tokens";

.Site__Header {
  background-color: tokens.$color-background-page-lightest;
  border-bottom: 1px solid tokens.$color-border-header;
  height: tokens.$size-site-nav-s;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: tokens.$spacing-site-nav-vertical-s;
  padding-bottom: tokens.$spacing-site-nav-vertical-s;

  @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
    height: tokens.$size-site-nav-m;
    padding-top: tokens.$spacing-site-nav-vertical-m;
    padding-bottom: tokens.$spacing-site-nav-vertical-m;
  }

  &__Nav {
    display: flex;
    align-items: center;
  }

  &__Search__Buttons {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__SearchBar__Wrapper {
    display: none;

    width: 100%;

    @include tokens.media-min(l) {
      display: block;
    }
  }

  &__Buttons__Wrapper {
    display: flex;
    margin-left: auto;
    margin-right: -#{tokens.$grid-spacing-s};
    padding-left: tokens.$grid-gutter-s;

    @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
      margin-right: 0;
    }

    @include tokens.media-min(l) {
      padding-left: tokens.$spacing-c-05;
    }
  }

  &__Login__Buttons__Wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__LoginButton {
    margin-left: tokens.$spacing-c-03;
    display: inline-block;
  }
}

.SearchButton {
  display: inline-block;

  @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
    display: none;
  }
}

.AddTodoMobile {
  display: inline-block;

  margin-right: tokens.$spacing-c-03;

  @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
    margin-right: tokens.$spacing-c-05;
  }
}
