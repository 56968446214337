@use "../../styles/tokens";

.PrimaryButton {
  background-color: tokens.$color-background-primary-enabled;
  border-radius: tokens.$border-radius-button-default;
  border: tokens.$size-border-button-default
    tokens.$color-border-primary-enabled solid;
  color: tokens.$color-text-button-primary-enabled;

  font: {
    size: tokens.$font-size-button-m;
    family: tokens.$font-family-button;
    weight: tokens.$font-weight-button;
  }

  line-height: tokens.$font-line-height-button-m;

  white-space: nowrap;
  height: tokens.$size-button-l;

  padding: tokens.$spacing-button-default-vertical-l
    tokens.$spacing-button-default-horizontal-l;

  &--Medium {
    height: tokens.$size-button-m;
    padding: tokens.$spacing-button-default-vertical-m
      tokens.$spacing-button-default-horizontal-m;
  }

  &--Small {
    height: tokens.$size-button-s;
    padding: tokens.$spacing-button-default-vertical-s
      tokens.$spacing-button-default-horizontal-s;
  }

  &--WithIcon {
    padding: tokens.$spacing-button-with-icon-vertical-l
      tokens.$spacing-button-with-icon-right-l
      tokens.$spacing-button-with-icon-vertical-l
      tokens.$spacing-button-with-icon-left-l;

    &--Medium {
      height: tokens.$size-button-m;
      padding: tokens.$spacing-button-with-icon-vertical-m
        tokens.$spacing-button-with-icon-right-m
        tokens.$spacing-button-with-icon-vertical-m
        tokens.$spacing-button-with-icon-left-m;
    }

    &--Small {
      height: tokens.$size-button-s;
      padding: tokens.$spacing-button-with-icon-vertical-s
        tokens.$spacing-button-with-icon-right-s
        tokens.$spacing-button-with-icon-vertical-s
        tokens.$spacing-button-with-icon-left-s;
    }
  }

  &--IconOnly {
    border-color: tokens.$color-border-icon-button-enabled;
    color: tokens.$color-icon-button-primary-enabled;

    padding: tokens.$spacing-button-icon-vertical-l
      tokens.$spacing-button-icon-horizontal-l;

    &--Medium {
      height: tokens.$size-button-m;
      padding: tokens.$spacing-button-icon-vertical-m
        tokens.$spacing-button-icon-horizontal-m;
    }

    &--Small {
      height: tokens.$size-button-s;
      padding: tokens.$spacing-button-icon-vertical-s
        tokens.$spacing-button-icon-horizontal-s;
    }

    &:hover {
      border-color: tokens.$color-border-icon-button-hover;
      color: tokens.$color-icon-button-primary-hover;
    }

    &:focus {
      border-color: tokens.$color-border-icon-button-focus;
      color: tokens.$color-icon-button-primary-focus;
    }

    .js-focus-visible &:focus:not([data-focus-visible-added]) {
      border-color: tokens.$color-border-icon-button-focus-polyfill;
      color: tokens.$color-icon-button-primary-focus;
    }

    &:active {
      border-color: tokens.$color-border-icon-button-active;
      color: tokens.$color-icon-button-primary-active;
    }

    &:disabled {
      border-color: tokens.$color-border-icon-button-disabled;
      color: tokens.$color-icon-button-primary-disabled;
    }
  }

  &__Icon {
    width: tokens.$size-icon-m;
    height: tokens.$size-icon-m;
    fill: currentColor;

    position: static;
    display: inline-block;
    vertical-align: bottom;

    &--Spaced {
      margin-right: tokens.rem(4);
    }
  }

  &:hover {
    background-color: tokens.$color-background-primary-hover;
    border-color: tokens.$color-border-primary-hover;
    box-shadow: tokens.$shadow-button-primary-hover;
    cursor: pointer;
    outline: none;
  }

  &:focus {
    background-color: tokens.$color-background-primary-focus;
    border-color: tokens.$color-border-primary-focus;
    box-shadow: tokens.$shadow-button-primary-focus;
    cursor: pointer;
    outline: none;
  }

  .js-focus-visible &:focus:not([data-focus-visible-added]) {
    background-color: tokens.$color-background-primary-focus-polyfill;
    border-color: tokens.$color-border-primary-focus-polyfill;
    box-shadow: none;
    cursor: pointer;
    outline: none;
  }

  &:active {
    background-color: tokens.$color-background-primary-active;
    border-color: tokens.$color-border-primary-active;
    box-shadow: none;
    cursor: pointer;
    outline: none;
  }

  &:disabled {
    background-color: tokens.$color-background-primary-disabled;
    border-color: tokens.$color-border-primary-disabled;
    box-shadow: none;
    outline: none;
    cursor: not-allowed;
    color: tokens.$color-text-button-primary-disabled;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
}
