@use "../../styles/tokens";

.SidebarAvatarSkeleton {
  margin: {
    top: tokens.$spacing-main-top-s;
    right: tokens.$grid-gutter-s * 2;
    bottom: tokens.$spacing-c-09;
    left: tokens.$grid-spacing-s;
  }

  padding: {
    left: tokens.$grid-spacing-m;
  }

  @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
    margin: {
      left: 0;
      right: tokens.$grid-spacing-m;
    }
  }

  @include tokens.media-min(xl) {
    margin-top: tokens.$spacing-main-top-m;
  }
}
