@use "../tokens";

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

%InputMedium {
  background-color: tokens.$color-background-input-enabled;
  border-radius: tokens.$border-radius-input-default;
  border: tokens.$size-border-input-default tokens.$color-border-input-enabled
    solid;
  color: tokens.$color-text-input-enabled;

  font: {
    size: tokens.$font-size-input-m;
    family: tokens.$font-family-body;
    weight: tokens.$font-weight-body;
  }

  line-height: tokens.$font-line-height-input-m;

  white-space: nowrap;
  height: tokens.$size-input-m;

  padding: {
    top: tokens.$spacing-input-vertical-m;
    left: tokens.$spacing-input-horizontal-m;
    right: tokens.$spacing-input-horizontal-m;
    bottom: tokens.$spacing-input-vertical-m;
  }
}

%InputSmall {
  background-color: tokens.$color-background-input-enabled;
  border-radius: tokens.$border-radius-input-default;
  border: tokens.$size-border-input-default tokens.$color-border-input-enabled
    solid;
  color: tokens.$color-text-input-enabled;

  font: {
    size: tokens.$font-size-input-m;
    family: tokens.$font-family-body;
    weight: tokens.$font-weight-body;
  }

  line-height: tokens.$font-line-height-button-s;

  white-space: nowrap;
  height: tokens.$size-input-s;

  padding: {
    top: tokens.$spacing-input-vertical-s;
    left: tokens.$spacing-input-horizontal-s;
    right: tokens.$spacing-input-horizontal-s;
    bottom: tokens.$spacing-input-vertical-s;
  }
}

%Todo__Form__Meta {
  background-color: tokens.$color-background-todo-meta-enabled;
  border-radius: tokens.$border-radius-button-default;
  border: tokens.$size-border-button-default
    tokens.$color-border-text-button-enabled solid;
  color: tokens.$color-text-button-text-enabled;

  &:hover {
    background-color: tokens.$color-background-todo-meta-hover;
    border-color: tokens.$color-border-text-button-hover;
    box-shadow: none;
    cursor: pointer;
    outline: none;
  }

  &:focus {
    background-color: tokens.$color-background-todo-meta-focus;
    border-color: tokens.$color-border-text-button-focus;
    box-shadow: tokens.$shadow-button-text-focus;
    cursor: pointer;
    outline: none;
  }

  .js-focus-visible &:focus:not([data-focus-visible-added]) {
    background-color: tokens.$color-background-todo-meta-focus-polyfill;
    border-color: tokens.$color-border-text-button-focus-polyfill;
    box-shadow: none;
    cursor: pointer;
    outline: none;
  }

  &:active {
    background-color: tokens.$color-background-todo-meta-active;
    border-color: tokens.$color-border-text-button-active;
    box-shadow: none;
    cursor: pointer;
    outline: none;
  }

  &:disabled {
    background-color: tokens.$color-background-todo-meta-disabled;
    border-color: tokens.$color-border-text-button-disabled;
    box-shadow: none;
    outline: none;
    cursor: not-allowed;
    color: tokens.$color-icon-button-text-disabled;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
}

%animate-modal-overlay {
  transition: opacity;
  animation: animate-modal-overlay tokens.$timing-modal-fadein ease-out;

  @keyframes animate-modal-overlay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

%animate-modal-contents {
  will-change: transform;
  transition: transform, opacity;
  animation: animate-modal-contents tokens.$timing-modal-fadein ease-out;

  @keyframes animate-modal-contents {
    from {
      opacity: 0;
      transform: translateY(1rem);
    }
    to {
      opacity: 1;
      transform: translateY(0rem);
    }
  }
}

%animate-spinner {
  animation: animate-spinner tokens.$timing-spinner infinite linear;

  @keyframes animate-spinner {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

%animate-todo-item-scroll-to {
  animation: animate-todo-item-scroll-to tokens.$timing-todo-item-scroll-to
    ease-in;

  @keyframes animate-todo-item-scroll-to {
    0%,
    100% {
      background: tokens.$color-background-todo-item-scroll-to-default;
    }
    50% {
      background: tokens.$color-background-todo-item-scroll-to-highlight;
    }
  }
}

%animate-placeholder {
  animation: animate-placeholder tokens.$timing-image-placeholder ease infinite;

  @keyframes animate-placeholder {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

%bottom-divider--on-white {
  border-bottom: tokens.$size-divider-s solid tokens.$color-divider-on-white;
}

%bottom-divider--on-grey {
  border-bottom: tokens.$size-divider-s solid tokens.$color-divider-on-grey;
}

%top-divider--on-white {
  border-top: tokens.$size-divider-s solid tokens.$color-divider-on-white;
}

%top-divider--on-grey {
  border-top: tokens.$size-divider-s solid tokens.$color-divider-on-grey;
}

%bg-debug {
  * {
    background-color: rgba(255, 0, 0, 0.2);
  }
  * * {
    background-color: rgba(0, 255, 0, 0.2);
  }
  * * * {
    background-color: rgba(0, 0, 255, 0.2);
  }
  * * * * {
    background-color: rgba(255, 0, 255, 0.2);
  }
  * * * * * {
    background-color: rgba(0, 255, 255, 0.2);
  }
  * * * * * * {
    background-color: rgba(255, 255, 0, 0.2);
  }
  * * * * * * * {
    background-color: rgba(255, 0, 0, 0.2);
  }
  * * * * * * * * {
    background-color: rgba(0, 255, 0, 0.2);
  }
  * * * * * * * * * {
    background-color: rgba(0, 0, 255, 0.2);
  }
}

%olg-debug {
  &,
  * {
    outline: 1px solid rgba(255, 0, 0, 0.2);
  }
  * * {
    outline: 1px solid rgba(0, 255, 0, 0.2);
  }
  * * * {
    outline: 1px solid rgba(0, 0, 255, 0.2);
  }
  * * * * {
    outline: 1px solid rgba(255, 0, 255, 0.2);
  }
  * * * * * {
    outline: 1px solid rgba(0, 255, 255, 0.2);
  }
  * * * * * * {
    outline: 1px solid rgba(255, 255, 0, 0.2);
  }
  * * * * * * * {
    outline: 1px solid rgba(255, 0, 0, 0.2);
  }
  * * * * * * * * {
    outline: 1px solid rgba(0, 255, 0, 0.2);
  }
  * * * * * * * * * {
    outline: 1px solid rgba(0, 0, 255, 0.2);
  }
}
