$grey900: #2a2f36;
$grey800: #424952;
$grey700: #646b73;
$grey600: #81878f;
$grey500: #a3a8ad;
$grey400: #bfc3c7;
$grey300: #d2d5d9;
$grey200: #e3e6e8;
$grey100: #edf0f2;
$grey50: #f5f6f7;
$grey00: #ffffff;
$primary900: #001761;
$primary800: #02267f;
$primary700: #093a9e;
$primary600: #1654bb;
$primary500: #2d71d5;
$primary400: #4e91e9;
$primary300: #7cb2f6;
$primary200: #b1d4fd;
$primary100: #cde3fe;
$primary50: #e8f3ff;
$cyan900: #0a2228;
$cyan800: #1b464e;
$cyan700: #2e6b73;
$cyan600: #43959a;
$cyan500: #59bfc0;
$cyan400: #69ccc8;
$cyan300: #7fd8d2;
$cyan200: #9ee5de;
$cyan100: #c2f3ec;
$cyan50: #ebfefb;
$purple900: #100535;
$purple800: #1e0d5a;
$purple700: #341880;
$purple600: #4c26a4;
$purple500: #6938c9;
$purple400: #895ad7;
$purple300: #ab83e4;
$purple200: #cdb0f2;
$purple100: #ebdcfc;
$purple50: #f7f0fe;
$danger900: #4f1200;
$danger800: #781b00;
$danger700: #9f2200;
$danger600: #bf300c;
$danger500: #d74223;
$danger400: #e85d45;
$danger300: #f48174;
$danger200: #fbb2ae;
$danger100: #fdcdcc;
$danger50: #ffebeb;
$success900: #001f0a;
$success800: #004817;
$success700: #007222;
$success600: #009b26;
$success500: #0cbf29;
$success400: #2eda38;
$success300: #6aec61;
$success200: #b1f6a0;
$success100: #cff7c1;
$success50: #eafae2;
