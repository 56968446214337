@use "../../styles/tokens";

.Logo {
  width: tokens.$size-logo-horizontal-s;
  max-width: tokens.$size-logo-horizontal-s;
  height: tokens.$size-logo-vertical-s;

  margin-left: -#{tokens.$grid-spacing-s};
  padding-left: tokens.$grid-spacing-s;

  @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
    width: tokens.$size-logo-horizontal-xl;
    max-width: tokens.$size-logo-horizontal-xl;
    height: tokens.$size-logo-vertical-xl;

    margin-left: -#{tokens.$grid-spacing-m};
    padding-left: tokens.$grid-spacing-m;
  }

  & svg {
    vertical-align: middle;
  }

  &__Link {
    display: block;
    width: 100%;
    height: 100%;
    background-color: tokens.$color-background-link-default;

    &:visited,
    &:link {
      background-color: tokens.$color-background-link-default;
    }

    &:hover {
      background-color: tokens.$color-background-link-hover;
      cursor: pointer;
    }

    &:active {
      background-color: tokens.$color-background-link-active;
      cursor: pointer;
    }
  }

  &__Combination {
    display: none;

    @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
      display: block;
    }
  }

  &__Mark {
    @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
      display: none;
    }
  }
}
