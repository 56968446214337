@use "../../styles/tokens";
@use "../../styles/base/placeholders";

.DatePicker {
  &__Overlay {
    overflow-x: visible;
    background-color: transparent;
    z-index: tokens.$z-index-modal-popup;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  &__Inner {
    background-color: tokens.$color-background-dropdown-inner;
    box-shadow: tokens.$shadow-dropdown;
    border-radius: tokens.$border-radius-components-default;
    display: block;

    position: absolute;
  }

  &__ErrorMessage {
    display: block;

    margin: {
      top: 0;
      right: tokens.$spacing-c-05;
      bottom: tokens.$spacing-c-03;
      left: tokens.$spacing-c-05;
    }

    padding-bottom: tokens.$spacing-c-03;
    font-size: tokens.$font-size-body-s;
    line-height: tokens.$font-line-height-body-s;
  }

  &__SubmitButton {
    margin-left: tokens.$spacing-c-05;
  }

  &__ButtonsRow {
    @extend %top-divider--on-white;

    display: flex;
    justify-content: space-between;

    margin: {
      right: tokens.$spacing-c-05;
      bottom: tokens.$spacing-c-03;
      left: tokens.$spacing-c-05;
    }

    padding: {
      top: tokens.$spacing-c-03;
    }
  }

  &__TimeInputRow {
    @extend %top-divider--on-white;

    margin: {
      left: tokens.$spacing-c-05;
      right: tokens.$spacing-c-05;
    }

    padding: {
      top: tokens.$spacing-c-04;
      bottom: tokens.$spacing-c-04;
    }
  }
}

// react-day-picker overides
.DayPicker {
  display: inline-block;
  font-size: tokens.$font-size-body-m;
  line-height: tokens.$font-line-height-body-m;

  &-TodayButton {
  }

  &-NavBar {
    display: flex;
    align-items: center;
  }

  &-NavButton {
    right: auto;
    left: auto;
    color: tokens.$color-background-datepicker-navbutton;
    padding: 0.25rem;
  }

  &-NavButton--prev {
    margin-right: 1em;
    left: 0.75rem;
  }

  &-NavButton--next {
    margin-left: 1em;
    right: 0.75rem;
  }

  &-Caption {
    margin-bottom: tokens.$spacing-c-03;
    padding: 0 2rem;
    margin-top: 0;
  }

  &-Weekdays {
    display: table-caption;
    margin-top: 0;
    margin-bottom: tokens.$spacing-c-03;
  }

  &-Caption > div {
    color: tokens.$color-text-datepicker-monthname;
    font-size: tokens.$font-size-datepicker-month;
    line-height: tokens.$font-line-height-datepicker-month;
    font-weight: 500;
    text-align: center;
  }

  &-Weekday {
    color: tokens.$color-text-datepicker-weekday;
    font-size: tokens.$font-size-datepicker-weekday;
    line-height: tokens.$font-line-height-datepicker-weekday;
    padding: tokens.$spacing-datepicker-weekday-vertical-m
      tokens.$spacing-datepicker-weekday-horizontal-m;
    display: table-cell;
    text-align: center;
  }

  &-Day {
    padding: tokens.$spacing-datepicker-day-vertical-m
      tokens.$spacing-datepicker-day-horizontal-m;
    border-radius: 0 !important;
    font-size: tokens.$font-size-datepicker-day;
    line-height: tokens.$font-line-height-datepicker-day;
  }

  &-Footer {
    padding-top: 0.5em;
  }

  /* Default modifiers */

  &-Day--outside {
    color: white;
    cursor: default;
  }

  &-Day--disabled {
    background-color: tokens.$color-background-datepicker-disabled !important;
    color: tokens.$color-text-datepicker-disabled !important;
    cursor: not-allowed;
  }

  &-Day--today {
    background-color: tokens.$color-background-datepicker-today !important;
    color: tokens.$color-text-datepicker-today !important;
  }

  &-Day:not(.DayPicker-Day--outside) {
    border: tokens.$size-border-datepicker-day solid
      tokens.$color-border-datepicker-enabled;
  }

  &-Day--start,
  &-Day--end {
    background-color: tokens.$color-text-datepicker-enabled !important;
    color: tokens.$color-background-datepicker-hover !important;
  }

  /* On hover */
  .DayPicker-Day:hover:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: tokens.$color-background-datepicker-hover !important;
    color: tokens.$color-text-datepicker-hover !important;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: tokens.$color-background-datepicker-selected !important;
    color: tokens.$color-text-datepicker-selected !important;
    font-weight: bold;
  }
}
