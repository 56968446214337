@use "../../styles/tokens";

.PasswordReset {
  background-color: tokens.$color-background-page-lightest;
  width: 100%;
  max-width: tokens.$size-account-page-xl;
  padding: 0 tokens.$spacing-c-05;
  margin-top: tokens.$spacing-l-03;
  margin-bottom: tokens.$spacing-l-06;

  margin-left: auto;
  margin-right: auto;

  @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
    border: tokens.$color-border-components-default solid
      tokens.$size-border-components-default;
    border-radius: tokens.$border-radius-components-default;
    padding: tokens.$spacing-c-09;
    margin-top: tokens.$spacing-l-07;
    margin-bottom: tokens.$spacing-l-08;
  }

  &__Title {
    font-size: tokens.$font-size-mobile-display-l;
    line-height: tokens.$font-line-height-mobile-display-l;
    margin-bottom: tokens.$spacing-c-07;

    @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
      font-size: tokens.$font-size-desktop-display-m;
      line-height: tokens.$font-line-height-desktop-display-m;
    }
  }

  &__Input {
    margin-bottom: tokens.$spacing-c-07;
  }

  &__Copy {
    margin-bottom: tokens.$spacing-c-07;
  }

  &__SubmitBtn {
    width: 100%;
  }
}
