@use "../../styles/tokens";
@use "../../styles/base/placeholders";

.Project__Card {
  background-color: tokens.$color-background-page-lighter;
  text-decoration: none;

  border-radius: tokens.$border-radius-components-default;

  margin-bottom: tokens.$grid-gutter-s;
  padding: tokens.$spacing-c-05;

  &:visited,
  &:link {
    color: tokens.$color-text-body-default;
  }

  &:hover {
    background-color: tokens.$color-background-page-lighter;
    box-shadow: tokens.$shadow-project-card;
    color: tokens.$color-text-body-default;
    text-decoration: none;
    cursor: pointer;
  }

  &:active {
    background-color: tokens.$color-background-page-lighter;
    color: tokens.$color-text-body-default;
    cursor: pointer;
  }

  @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
    margin-bottom: tokens.$grid-gutter-m;
    box-shadow: none;
  }

  &__Header {
    display: flex;
  }

  &__Title {
    font-size: tokens.$font-size-mobile-display-xs;
    line-height: tokens.$font-line-height-mobile-display-xs;
    margin-bottom: tokens.$spacing-c-10;

    @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
      font-size: tokens.$font-size-mobile-display-xs;
      line-height: tokens.$font-line-height-mobile-display-xs;
    }
  }

  &__Icon {
    width: tokens.$size-icon-m;
    height: tokens.$size-icon-m;
    margin-left: auto;
  }

  &__Count {
    color: tokens.$color-text-body-subdued;
  }
}
