@use "../../styles/tokens";
@use "../../styles/base/placeholders";

.MobileSearchBar {
  background-color: tokens.$color-background-search-bar-mobile;
  display: block;

  width: 100%;
  height: 100%;

  &__LabelWrapper {
    background-color: tokens.$color-background-mobile-search-bar-background;
    @extend %bottom-divider--on-white;

    padding: {
      top: tokens.$spacing-c-03;
      right: 0;
      bottom: tokens.$spacing-c-03;
      left: 0;
    }
  }

  &__Label {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-right: tokens.$spacing-search-bar-horizontal;

    background-color: tokens.$color-background-search-bar-mobile;
    border-radius: tokens.$border-radius-input-default;
    border: tokens.$size-border-input-default tokens.$color-border-input-enabled
      solid;

    font: {
      size: tokens.$font-size-input-m;
      family: tokens.$font-family-body;
      weight: tokens.$font-weight-body;
    }

    line-height: tokens.$font-line-height-input-m;

    white-space: nowrap;
    height: tokens.$size-input-m;

    margin: {
      left: tokens.$spacing-c-04;
      right: tokens.$spacing-c-04;
    }

    padding: {
      top: 0;
      left: tokens.$spacing-search-bar-horizontal;
      right: 0;
      bottom: 0;
    }
  }

  &__Input {
    background-color: transparent;
    border: none;
    color: tokens.$color-text-input-enabled;
    margin: 0;
    height: 100%;
    width: 100%;
    flex: 1 1 auto;

    font: {
      size: tokens.$font-size-input-m;
      family: tokens.$font-family-body;
      weight: tokens.$font-weight-body;
    }

    line-height: tokens.$font-line-height-button-s;

    padding: {
      top: tokens.$spacing-search-bar-vertical;
      left: 0;
      right: tokens.$spacing-search-bar-horizontal;
      bottom: tokens.$spacing-search-bar-vertical;
    }

    &:focus {
      outline: none;
    }
  }

  &__Icon {
    margin-right: tokens.rem(8);
    flex: 0 0 auto;
    width: tokens.$size-icon-m;
    height: tokens.$size-icon-m;
    fill: tokens.$color-icon-default;
    display: block;
  }

  &__SectionTitle {
    color: tokens.$color-text-subhead-default;

    font-size: tokens.$font-size-subhead;
    line-height: tokens.$font-line-height-subhead;
    font-family: tokens.$font-family-display;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: tokens.$font-letter-spacing-subhead;

    padding: {
      left: tokens.$spacing-c-05;
      right: tokens.$spacing-c-05;
      bottom: tokens.$spacing-c-03;
    }
  }

  &__Hits {
    display: block;
    margin-left: 0;

    overflow-x: auto;

    padding: {
      top: tokens.$spacing-c-05;
      bottom: tokens.$spacing-mobile-search-bar-list;
    }

    width: 100%;
    height: 100%;

    &__Item {
      background-color: tokens.$color-background-dropdown-item-enabled;
      text-decoration: none;
      color: inherit;
      font-size: inherit;
      line-height: inherit;

      display: flex;
      align-items: center;
      flex: 1 1 auto;

      padding: {
        top: tokens.$spacing-c-04;
        right: tokens.$spacing-c-05;
        bottom: tokens.$spacing-c-04;
        left: tokens.$spacing-c-05;
      }

      &:hover {
        background-color: tokens.$color-background-dropdown-item-hover;
        color: tokens.$color-text-body-default;
        text-decoration: none;
        cursor: pointer;
      }

      &__Checkbox {
        background-color: tokens.$color-background-todo-checkbox-enabled;
        border: tokens.$color-border-todo-checkbox-enabled
          tokens.$border-todo-checkbox solid;
        border-radius: 50%;
        display: block;
        flex-shrink: 0;

        margin-right: tokens.$spacing-c-03;
        width: tokens.$size-button-todo-search;
        height: tokens.$size-button-todo-search;
      }

      &__Project {
        margin-left: auto;
        padding-left: tokens.$spacing-c-04;
        text-align: right;
        display: flex;

        &__Color {
          width: tokens.$size-icon-m;
          height: tokens.$size-icon-m;
          margin-left: tokens.$spacing-c-02;
        }
      }

      &--Selected {
        font-weight: 700;
      }

      &--Highlighted {
        background-color: tokens.$color-background-dropdown-item-hover;
      }
    }
  }

  &__AlgoliaWrapper {
    list-style-type: none;
    background-color: tokens.$color-background-algolia-link;
    display: flex;
    justify-content: flex-end;

    margin: {
      top: tokens.$spacing-c-04;
    }

    padding: {
      top: tokens.$spacing-c-04;
      right: tokens.$spacing-c-07;
      bottom: tokens.$spacing-c-04;
    }
  }

  &__AlgoliaLink {
    list-style-type: none;
    display: block;

    &:hover {
      background: none !important;
    }

    & svg {
      vertical-align: middle;
    }
  }
}
