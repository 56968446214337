@use "../../styles/tokens";
@use "../../styles/base/placeholders";

.ProjectModal {
  overflow-x: auto;

  &__Overlay {
    overflow-x: auto;
    background-color: tokens.$color-background-modal-overlay;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @extend %animate-modal-overlay;
  }

  &__Inner {
    background-color: tokens.$color-background-modal-inner;
    border-radius: tokens.$border-radius-components-default;
    box-shadow: tokens.$shadow-modal-background;

    width: 100%;
    max-width: tokens.$size-modal-xl;

    padding: tokens.$spacing-c-05;

    margin: {
      top: tokens.$spacing-modal-top-s;
      right: tokens.$spacing-c-05;
      bottom: tokens.$spacing-c-05;
      left: tokens.$spacing-c-05;
    }

    @extend %animate-modal-contents;

    @include tokens.media-min(s) {
      margin-top: tokens.$spacing-modal-top-m;
    }

    @include tokens.media-min(l) {
      margin-top: tokens.$spacing-modal-top-xl;
    }

    @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
      padding: tokens.$spacing-c-07;
    }

    @include tokens.media-max-height(xl) {
      margin-top: tokens.$spacing-modal-top-small-height;
    }
  }

  &__TitleRow {
    display: flex;
    align-items: center;
    margin-bottom: tokens.$spacing-c-07;
  }

  &__Title {
    color: tokens.$color-text-display-default;

    font-size: tokens.$font-size-mobile-display-xs;
    line-height: tokens.$font-line-height-mobile-display-xs;
    font-family: tokens.$font-family-display;
    font-weight: 700;
    font-style: normal;

    margin: 0;

    @include tokens.media-min(tokens.$grid-gutter-breakpoint-change) {
      font-size: tokens.$font-size-desktop-display-xs;
      line-height: tokens.$font-line-height-desktop-display-xs;
    }
  }

  &__CloseButton {
    margin-left: auto;
  }

  &__Form {
    display: flex;
    flex-direction: column;
  }

  &__MetaRow {
    display: flex;
    flex-wrap: wrap;
    margin-top: tokens.$spacing-c-04;
  }

  &__CTARow {
    display: flex;
    justify-content: flex-end;
    @extend %top-divider--on-white;
    padding-top: tokens.$spacing-c-05;
    margin-top: tokens.$spacing-c-07;
  }

  &__CancelBtn {
    margin-right: tokens.$spacing-c-05;
    display: inline-block;
  }
}
