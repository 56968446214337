@use "../../styles/tokens";

.UserAvatar {
  display: flex;

  &__Image {
    width: tokens.$size-avatar-m;
    height: tokens.$size-avatar-m;
    border: tokens.$border-avatar-default tokens.$color-border-avatar-on-white
      solid;
    background-color: tokens.$color-background-avatar-on-white;
    border-radius: 50%;
    object-fit: cover;
    display: block;
  }

  &__Copy {
    margin-left: tokens.$spacing-c-03;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__Name {
    color: tokens.$color-text-body-default;
    font-size: tokens.$font-size-body-s;
    line-height: tokens.$font-line-height-body-s;
    font-family: tokens.$font-family-display;
    font-weight: 700;
  }

  &__Email {
    color: tokens.$color-text-body-subdued;
    font-size: tokens.$font-size-body-s;
    line-height: tokens.$font-line-height-body-s;
  }
}
