@use "../../styles/tokens";
@use "../../styles/base/placeholders";

.Projects__Section__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: tokens.$spacing-c-05;
  margin-bottom: tokens.$spacing-c-07;

  @extend %bottom-divider--on-white;
}
